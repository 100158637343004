import { Actions as AuthActions } from "./actions";

const initialState = {
  authToken: null,
  userProfile: null,
  status: "UNKNOWN",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthActions.SET_AUTH_STATUS:
      return {
        ...state,
        authToken: action.value.authToken,
        userProfile: action.value.userProfile,
        status: action.value.status,
      };
    case AuthActions.LOGOUT:
      return { ...initialState, status: "UNAUTHENTICATED" };
    default:
      return state;
  }
};

export default authReducer;
