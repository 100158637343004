// ** React Imports
import { Suspense } from "react";
import ReactDOM from "react-dom";
import { lazyWithRetry } from "@utility/react";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/store";

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";

// ** Config API with AAxios
import "./api/config";

// ** Lazy load app
const LazyApp = lazyWithRetry(() => import("./App"));

Sentry.init({
  environment: process.env.REACT_APP_ENV, // "localhost", "dev", "staging", "production"
  dsn: "https://933acc7341f94827b5e7b45d3098a2ca@o672973.ingest.sentry.io/6417045",
  integrations: [new BrowserTracing()],
  enabled: process.env.REACT_APP_ENV !== "localhost",
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <ThemeContext>
        <LazyApp />
        <ToastContainer newestOnTop />
      </ThemeContext>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
