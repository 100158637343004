import { all } from "redux-saga/effects";
import appWatchers from "./app/watchers";
import authWatchers from "./auth/watchers";
import staffWatchers from "./staff/watchers";
import clientWatchers from "./client/watchers";
import resourceWatchers from "./resource/watchers";
import companySettingsWatchers from "./companySettings/watchers";

function* rootSaga() {
  yield all([
    appWatchers(),
    authWatchers(),
    clientWatchers(),
    staffWatchers(),
    resourceWatchers(),
    companySettingsWatchers(),
  ]);
}

export default rootSaga;
