import { put, all, takeLatest, call } from "redux-saga/effects";
import { Actions as ClientActions, setClientProfiles } from "./actions";
import { getClientProfiles } from "@src/api/lib/client";

function* onfetchClientProfiles() {
  try {
    const response = yield call(getClientProfiles);
    yield put(setClientProfiles(response.data));
  } catch (error) {
    console.log("error", error);
  }
}

export default function* appWatchers() {
  yield all([
    takeLatest(ClientActions.FETCH_CLIENT_PROFILES, onfetchClientProfiles),
  ]);
}
