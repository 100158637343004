const ns = "@@resources";

export const Actions = {
  FETCH_RESOURCES: `${ns}/FETCH_RESOURCES`,
  SET_RESOURCES: `${ns}/SET_RESOURCES`,
};

export const fetchResources = () => ({
  type: Actions.FETCH_RESOURCES,
});

export const setResources = (value) => ({
  type: Actions.SET_RESOURCES,
  value,
});
