const ns = "@@app";

export const Actions = {
  APP_INIT_REQUEST: `${ns}/APP_INIT_REQUEST`,
  APP_LOADING: `${ns}/APP_LOADING`,
};

export const appInitRequest = () => ({
  type: Actions.APP_INIT_REQUEST,
});

export const appLoading = (value) => ({
  type: Actions.APP_LOADING,
  value,
});
