import { put, all, takeLatest, call } from "redux-saga/effects";
import { Actions as StaffActions, setStaffProfiles } from "../staff/actions";
import { getStaffProfiles } from "@src/api/lib/staff";

function* onfetchStaffProfiles() {
  try {
    const response = yield call(getStaffProfiles);
    yield put(setStaffProfiles(response.data));
  } catch (error) {
    console.log("error", error);
  }
}

export default function* appWatchers() {
  yield all([
    takeLatest(StaffActions.FETCH_STAFF_PROFILES, onfetchStaffProfiles),
  ]);
}
