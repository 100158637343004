import { put, all, takeLatest, call } from "redux-saga/effects";
import { Actions as ResourceActions, setResources } from "./actions";
import { getResources } from "@src/api/lib/resources";

function* onfetchResources() {
  try {
    const response = yield call(getResources);
    yield put(setResources(response.data.data));
  } catch (error) {
    console.log("error", error);
  }
}

export default function* resourceWatchers() {
  yield all([takeLatest(ResourceActions.FETCH_RESOURCES, onfetchResources)]);
}
