import { put, all, takeLatest, call, delay } from "redux-saga/effects";
import {
  Actions as CompanySettingsActions,
  setCompanySettings,
} from "./actions";
import { getCompanySettings } from "@src/api/lib/companySettings";
import { getAttachmentById } from "@src/api/lib/attachment";
import { appLoading } from "../app/actions";

function* onfetchCompanySettings() {
  try {
    const response = yield call(getCompanySettings);
    const logoId = response?.data?.attachment;
    const finalResponse = {
      ...response.data,
    };
    if (logoId) {
      const logoResponse = yield call(getAttachmentById, logoId);
      finalResponse.Attachment = logoResponse;
    }
    yield put(setCompanySettings(finalResponse));
  } catch (error) {
    console.log("error", error);
  } finally {
    yield put(appLoading(false));
  }
}

export default function* companySettingsWatchers() {
  yield all([
    takeLatest(
      CompanySettingsActions.FETCH_COMPANY_SETTINGS,
      onfetchCompanySettings
    ),
  ]);
}
