// ** Redux, Thunk & Root Reducer Imports
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import createDebounce from "redux-debounced";
import rootReducer from "./rootReducer";
import { createStore, applyMiddleware, compose } from "redux";
import rootSaga from "./rootSaga";
import { appInitRequest } from "./app/actions";

// ** init middleware
const sagaMiddleware = createSagaMiddleware();
const middleware = [thunk, sagaMiddleware, createDebounce()];

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// ** Create store
const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middleware))
);

// start the saga and dispatch a init request
sagaMiddleware.run(rootSaga);
store.dispatch(appInitRequest());

export { store };
