import axios from "../config";

export const getAttachmentById = async (id) => {
  const response = await axios.get(`attachment/${id}`, {
    responseType: "blob",
  });

  if (response.data) {
    return URL.createObjectURL(new Blob([response.data]));
  }
  return null;
};
