import { put, all, takeLatest } from "redux-saga/effects";
import { Actions as AppActions } from "./actions";
import { checkAuthStatus } from "../auth/actions";
import { appLoading } from "../app/actions";

function* initializeApp() {
  yield put(appLoading(true));
  yield put(checkAuthStatus());
}

export default function* appWatchers() {
  yield all([takeLatest(AppActions.APP_INIT_REQUEST, initializeApp)]);
}
