const ns = "@@staff";

export const Actions = {
  FETCH_STAFF_PROFILES: `${ns}/FETCH_STAFF_PROFILES`,
  SET_STAFF_PROFILES: `${ns}/SET_STAFF_PROFILES`,
};

export const fetchStaffProfiles = () => ({
  type: Actions.FETCH_STAFF_PROFILES,
});

export const setStaffProfiles = (value) => ({
  type: Actions.SET_STAFF_PROFILES,
  value,
});
