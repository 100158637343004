const ns = "@@client";

export const Actions = {
  FETCH_CLIENT_PROFILES: `${ns}/FETCH_CLIENT_PROFILES`,
  SET_CLIENT_PROFILES: `${ns}/SET_CLIENT_PROFILES`,
};

export const fetchClientProfiles = () => ({
  type: Actions.FETCH_CLIENT_PROFILES,
});

export const setClientProfiles = (value) => ({
  type: Actions.SET_CLIENT_PROFILES,
  value,
});
