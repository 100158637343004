const ns = "@@auth";

export const Actions = {
  CHECK_AUTH_STATUS: `${ns}/CHECK_AUTH_STATUS`,
  SET_AUTH_STATUS: `${ns}/SET_AUTH_STATUS`,
  LOGOUT: `${ns}/LOGOUT`,
};

export const checkAuthStatus = () => ({
  type: Actions.CHECK_AUTH_STATUS,
});

export const setAuthStatus = (value) => ({
  type: Actions.SET_AUTH_STATUS,
  value,
});

export const logout = () => ({
  type: Actions.LOGOUT,
});
