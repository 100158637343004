import { Actions as ClientActions } from "./actions";

const initialState = {
  clientProfiles: [],
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case ClientActions.SET_CLIENT_PROFILES:
      return { ...state, clientProfiles: action.value };
    default:
      return state;
  }
};

export default clientReducer;
