import { Actions as CompanySettingsActions } from "./actions";

const initialState = {
  settings: null,
};

const companySettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CompanySettingsActions.SET_COMPANY_SETTINGS:
      return { ...state, settings: action.value };
    default:
      return state;
  }
};

export default companySettingsReducer;
