import { configure } from "axios-hooks";
import Axios from "axios";
import * as localStorage from "@utility/storage/local";
import * as sessionStorage from "@utility/storage/session";

// const baseURL = "https://care-diary-api-5tpye.ondigitalocean.app";
const baseURL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const axios = Axios.create({
  baseURL,
});

// request interceptor to add token to request headers
axios.interceptors.request.use(
  async (config) => {
    let jwt;

    jwt = localStorage.getItem(localStorage.AUTH_TOKEN_KEY);
    if (!jwt) {
      jwt = sessionStorage.getItem(sessionStorage.AUTH_TOKEN_KEY);
    }

    if (jwt) {
      config.headers = {
        Authorization: `Bearer ${jwt}`,
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

/**
 * Catch the UnAuthorized Request
 */
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== "/auth/login"
    ) {
      localStorage.clear();
      sessionStorage.clear();
      window.location = "/auth/login";
    }
    return Promise.reject(error);
  }
);

configure({ axios });

export default axios;
