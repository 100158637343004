const store = window.localStorage;
export const AUTH_TOKEN_KEY = "auth-token";

export function getItem(key) {
  return store.getItem(key);
}

export function setItem(key, value) {
  store.setItem(key, value);
}

export function clear() {
  store.clear();
}
