import { all, takeLatest, call, put } from "redux-saga/effects";
import { Actions as AuthActions, setAuthStatus } from "./actions";
import * as localStorage from "@utility/storage/local";
import * as sessionStorage from "@utility/storage/session";
import { getUserProfile } from "@src/api/lib/auth";
import { fetchResources } from "../resource/actions";
import { fetchCompanySettings } from "../companySettings/actions";
import { appLoading } from "../app/actions";

function* onLogout() {
  yield call(localStorage.clear);
  yield call(sessionStorage.clear);
}

function* checkAuthStatus() {
  let jwt;

  jwt = yield call(localStorage.getItem, localStorage.AUTH_TOKEN_KEY);
  if (!jwt) {
    jwt = yield call(sessionStorage.getItem, sessionStorage.AUTH_TOKEN_KEY);
  }

  if (jwt) {
    try {
      const myProfile = yield call(getUserProfile, jwt);

      yield put(
        setAuthStatus({
          authToken: jwt,
          userProfile: myProfile.data,
          status: "AUTHENTICATED",
        })
      );
      yield put(fetchResources());
      yield put(fetchCompanySettings());
    } catch (error) {
      console.log("error", error);
    }
  } else {
    yield put(
      setAuthStatus({
        status: "UNAUTHENTICATED",
      })
    );
    yield put(appLoading(false));
  }
}

export default function* authWatchers() {
  yield all([
    takeLatest(AuthActions.LOGOUT, onLogout),
    takeLatest(AuthActions.CHECK_AUTH_STATUS, checkAuthStatus),
  ]);
}
