const ns = "@@companySettings";

export const Actions = {
  FETCH_COMPANY_SETTINGS: `${ns}/FETCH_COMPANY_SETTINGS`,
  SET_COMPANY_SETTINGS: `${ns}/SET_COMPANY_SETTINGS`,
};

export const fetchCompanySettings = () => ({
  type: Actions.FETCH_COMPANY_SETTINGS,
});

export const setCompanySettings = (value) => ({
  type: Actions.SET_COMPANY_SETTINGS,
  value,
});
