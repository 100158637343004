import { Actions as StaffActions } from "./actions";

const initialState = {
  staffProfiles: [],
};

const staffReducer = (state = initialState, action) => {
  switch (action.type) {
    case StaffActions.SET_STAFF_PROFILES:
      return { ...state, staffProfiles: action.value };
    default:
      return state;
  }
};

export default staffReducer;
