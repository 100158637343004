// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import navbar from "./reducers/navbar";
import layout from "./reducers/layout";
import staffReducer from "./staff/reducers";
import clientReducer from "./client/reducers";
import authReducer from "./auth/reducers";
import resourceReducer from "./resource/reducers";
import companySettingsReducer from "./companySettings/reducers";
import appReducer from "./app/reducers";

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  navbar,
  layout,
  staff: staffReducer,
  client: clientReducer,
  resource: resourceReducer,
  companySettings: companySettingsReducer,
});

export default rootReducer;
